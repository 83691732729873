<template>
    <section class="advanced-form-elements new-updated-design">
         <div class="row">
            <div class="col-md-12 grid-margin">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('settings') }}</h4>
                    </div>
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <div>
                                <h5>{{ $t('ProSync-desktop') }}</h5>
                                <h5>{{ $t('latest-version-number') }}: {{ versionNumber }}</h5>
                            </div>
                            <button @click="handleDownload" class="btn btn-success ml-5">
                                 {{  $t('download') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="js">
import API from "@/api";

export default {
    name: 'projectSettings',

    data() {
        return {
            versionNumber: '', // This will hold the extracted version number
            fileUrl: '', // This will hold the dynamic file URL for downloading
        }
    },
    mounted() {
        this.fetchVersionNumber(); // Fetch the latest version number on component mount
    },
    methods: {
        fetchVersionNumber() {
            API.getVersionNumber(
                (data) => {
                    this.versionNumber = data.version; // Set the version number
                    this.fileUrl = data.fileUrl; // Set the dynamic file URL
                },
                (error) => {
                    console.error('Error fetching version number:', error);
                }
            );
        },
        handleDownload() {
            const link = document.createElement('a');
            link.href = this.fileUrl; // Use the dynamic file URL for the download
            link.download = `Watchspotter-ProSync-${this.versionNumber}.zip`;

            // Trigger the file download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
}
</script>
<style lang="scss" scoped>
.card-body {
    min-height: 200px !important;
    vertical-align: middle !important;
    margin-top: 10px;
    h3 {
        color: rgb(33, 33, 33);
        font-size: 18px;
    }
}
</style>
